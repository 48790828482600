<template>
  <div class="item">
     <Breadcrumb :sn="sn"></Breadcrumb>
     <el-row class="content">
            <el-col :span="24">
                  <div class="titBox">
                <TitleHeader msg="订单信息" :noBorder="true"></TitleHeader>
                  </div>
                <el-row>
                    <el-col class="wraper">
                        <el-col :span="8"><span class="titmsg">订单状态：</span> <span class="desMsg">{{ orderdetail.status===1?"待支付":orderdetail.status===2?"待发货":orderdetail.status===3?"待收货":orderdetail.status===4?"已完成":orderdetail.status===4?"已完成":"已取消" }}</span></el-col>
                        <el-col  :span="8"><span class="titmsg">订单ID：</span><span class="desMsg">{{ orderdetail.id }}</span></el-col>
                         <el-col  :span="8"><span class="titmsg">订单编号：</span><span class="desMsg">{{ orderdetail.sn }}</span></el-col>
                        <el-col  :span="8"><span class="titmsg">下单时间：</span><span class="desMsg">{{orderdetail.createTime}}</span></el-col>
                    </el-col>
                </el-row>
             </el-col>
            <el-col :span="24">
                  <div class="titBox">
                <TitleHeader msg="发票信息" :noBorder="true"></TitleHeader>
                  </div>
                <el-row>
                    <el-col class="wraper">
                      <el-col :span="8"><span class="titmsg">开票方式：</span><span  class="desMsg">{{invoiceInfo.invoiceType}}</span></el-col>
                      <el-col :span="8"><span class="titmsg">发票类型：</span><span  class="desMsg">{{invoiceInfo.invoiceManner}}</span></el-col>
                        <!-- <el-col :span="8"><span class="titmsg">发票状态：</span><span  class="desMsg">{{invoiceInfo.status}}</span></el-col>
                        <el-col  :span="8"><span class="titmsg">发票类型：</span><span class="desMsg">{{invoiceInfo.type}}</span></el-col>
                        <el-col  :span="8"><span class="titmsg">发票内容：</span><span class="desMsg">{{invoiceInfo.content}}</span></el-col>
                        <el-col  :span="8"><span class="titmsg">发票抬头：</span><span class="desMsg">{{invoiceInfo.title}}</span></el-col>
                        <el-col  :span="8"><span class="titmsg">发票税号：</span><span class="desMsg">{{invoiceInfo.tax}}</span></el-col> -->
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="24">
                  <div class="titBox">
                <TitleHeader msg="收货信息" :noBorder="true"></TitleHeader>
                  </div>
                <el-row>
                    <el-col class="wraper noborder">
                       <el-col  :span="24">
                          <el-col :span="8"><span class="titmsg">收票人：</span><span class="desMsg">{{takerInfo.taker}}</span></el-col>
                          <el-col  :span="8"><span class="titmsg">联系电话：</span><span class="desMsg">{{takerInfo.phone}}</span></el-col>
                        </el-col>
                        <el-col  :span="16"><span class="titmsg">详细地址：</span><span class="desMsg">{{takerInfo.address}}</span></el-col>
                    </el-col>
                </el-row>
             </el-col>
     </el-row>
  </div>
</template>

<script>
import {OrderDetail} from "api/order.js";
    const TitleHeader = ()=>import("components/common/TitleHeader.vue");
    const Breadcrumb = ()=>import('components/common/Breadcrumb.vue')

  export default {
    name:"MyOrderInvoice",
    data() {
      return {
            sn:'',
            orderdetail:'',
             invoiceManner: "", //发票类型
              invoiceType: "", //开票方式
            invoiceInfo:'', // 发票信息
            takerInfo:''// 收票信息
        }
    },
    methods:{
    // 通过订单sn获取订单详情
     getOrderDetail() {
        OrderDetail(sessionStorage.getItem("orderId")).then((res) => {
            this.orderdetail = res.data.data;
            // 收票信息   数据未接
            this.takerInfo={
                taker: this.orderdetail.consigneeName,
                phone: this.orderdetail.consigneeTel,
                address:this.orderdetail.address,
                // taker:this.orderdetail.memberStore.linkman,
                // phone:this.orderdetail.memberStore.mobile,
                // address: this.orderdetail.memberStore.addressProvinceString +
                //         this.orderdetail.memberStore.addressCityString +
                //         this.orderdetail.memberStore.addressAreaString +
                //         this.orderdetail.memberStore.address,
            }

          //发票信息
          switch (this.orderdetail.memberStore.invoiceType) {
            case 1:
              this.invoiceType = "纸质发票";
              break;
            case 2:
              this.invoiceType = "电子发票";
              break;

            default:
              this.invoiceType = "无";
              break;
          }
          switch (this.orderdetail.memberStore.invoiceManner) {
            case 1:
              this.invoiceManner = "普票";
              break;
            case 2:
              this.invoiceManner = "增值税专用发票";
              break;
            default:
              this.invoiceManner = "无";
              break;
          }


            // 发票信息  数据未接
            this.invoiceInfo={
              invoiceType:this.invoiceType,//开票方式
              invoiceManner:this.invoiceManner//发票类型
                // status:"已开票",
                // type:"普通发票",
                // content:"商品明细",
                // title:"xxxxx",
                // tax:"5568452266969"
            }
           
        });
    },
   },
   created(){
     this.getOrderDetail();
     this.sn=sessionStorage.getItem("orderId")
    },
     components:{
       TitleHeader,
       Breadcrumb
      
    }
  }
</script>
<style lang="less" scoped>
@import '~style/index.less';
.item {
    .content {
         min-height: 500px;
        background:#fff;
        padding: 10px;
        .titBox{
  padding: 20px;

}
         .wraper {
            margin:0 20px;
            width: calc(100% - 40px);
            line-height: 60px;
            border-bottom: 1px solid #F0F0F0;
            &.noborder{
                 border-bottom:none;
            }
            .titmsg{
                padding-left: 15px;
                color: #666;
                font-size: 14px;
            }
            .desMsg{
                 color: #333;
                font-size: 14px;
            }
        }
    }
   
}
 
</style>
